import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Close } from "../../components/svgIcons";
import { Spin } from "antd";
import { LoadingOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { size } from "lodash";
import moment from "moment";

export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState("all");
  const [nextStep, setNextStep] = useState(0);
  const [loader, setLoader] = useState(true);
  const [isCalled, setIsCallApi] = useState(false);
  const [stateData, setStateData] = useState("");
  const [dataList, setDataList] = useState({});
  const [sort, setSort] = useState(false);
  const [type, setType] = useState(-1);
// 2 other news
// 01 themes
  const callApi = async (name, sort=false, t=-1) => {
    const { value } = await props.getThemeAndOtherNewByName({ titleCategory: name, page: page, count: 100, sort: sort, type: t });
    setDataList(value);
    setLoader(false);
  };

  const changeTab = async (tab) => {
    setLoader(true);
    setTab(tab);
    let tagName = location?.pathname?.split("/")[2]
    let tagNew = tagName?.replaceAll("_"," ")?.replaceAll("--","/").replaceAll("&","%26")
    if (tab === "other-news") {
      setSort(false)
      setType(2)
      setPage(1);
      callApi(tagNew, false, 2)
      callCountApi(tagNew, 2)
      navigate(`/iim-tags/${tagName}/other-news`);
    } else if (tab === "themes") {
      setType(1)
      setSort(false)
      setPage(1);
      callApi(tagNew, false, 1)
      callCountApi(tagNew, 1)
      navigate(`/iim-tags/${tagName}/themes`);
    }else {
      setSort(false)
      setType(-1)
      setPage(1);
      callApi(tagNew, false, -1)
      callCountApi(tagNew, -1)
      navigate(`/iim-tags/${tagName}/all`);
    }
  };


  const pageChange = async (value) => {
    setLoader(true);
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.getThemeAndOtherNewByName({ titleCategory: stateData, page: pageNum, count: 100, sort: sort, type: type  });
      setDataList(value);
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.getThemeAndOtherNewByName({ titleCategory: stateData, page: pageNum, count: 100, sort: sort, type: type  });
      setDataList(value);
    }
    setLoader(false);
  };

  const callCountApi = async (name, t=-1) => {
    const { value } = await props.GetCategoryCount({titleCategory: name, type: t })
    // console.log()
    let nextPageCount = parseInt(Math.ceil(value / 100));
    setNextStep(nextPageCount);
  }

  useEffect(() => {
    if (!isCalled) {
      let tagName = location?.pathname?.split("/")[2]
      let tagn = location?.pathname?.split("/")[3]
      changeTab(tagn)
      setTab(tagn)
      tagName = tagName?.replaceAll("_"," ")?.replaceAll("--","/")
      setStateData(tagName);
      // callApi(tagName);
      // callCountApi(tagName, true, )
      setIsCallApi(true);
    }
  }, [dataList, stateData]);


  return (
    <>
      <main class="main home__thmes-wrapper" role="header">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/iim-tags")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">
                    {stateData}
                  </div>
                </div>
              </div>
              
            </div>
          </header>

          </div>
          <div className="page__header--left">
          <div className="inner-tabs-container">
           {/* <button
            className={tab === "all" ? "tabs__link active" : "tabs__link"}
            changes
            onClick={() => changeTab("all")}
          > */}
            <Link
              className={tab === "all" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/all`}
              onClick={() => changeTab('all')}
            >
            All
            </Link>
          {/* </button> */}
          {/* <button
          className={tab === "themes" ? "tabs__link active" : "tabs__link"}
          changes
          onClick={() => changeTab("themes")}
        > */}
        <Link
              className={tab === "themes" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/themes`}
              onClick={() => changeTab('themes')}
            >
          Themes
        </Link>
        <Link
          className={tab === "other-news" ? "tabs__link active" : "tabs__link"}
              to={`/iim-tags/${location?.pathname?.split("/")[2]}/other-news`}
              onClick={() => changeTab("other-news")}
            >
          Other News
        </Link>
          
        </div>
          {/* <div className="tabs__links tabs__links--space">
          <div className="tabs__links tabs__links--space">
        </div>
        </div> */}
        </div>
        <div className="custom__container">
          <div className="table-responsive table__responsive--custom">
            <table className="table table__custom table-fixed">
              <thead>
                <tr>
                  <th>
                  {sort ?
                    <button className="sort-btn" onClick={()=>{
                      setSort(!sort)
                      callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort, type)
                    }
                    }>IIM Date<ArrowUpOutlined /></button>
                     :
                    <button className="sort-btn" onClick={()=>{setSort(!sort)
                      callApi(location?.pathname?.split("/")[2]?.replaceAll("_"," ")?.replaceAll("--","/"), !sort, type)}}>IIM Date <ArrowDownOutlined /></button>
                    }
                    </th>
                  <th>TYPE</th>
                  <th>Theme/Article title</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  dataList?.length > 0 &&
                  dataList?.map((news, i) => {
                    return (
                      <tr key={i}>
                        <td>Week ending {moment(new Date(news.Created)).format("MMM DD, YYYY")}</td>
                        <td> {news.ExternalUrl === "" ? "Theme " + news.ItemName : "Other News" }</td>
                        {news.ExternalUrl === "" ? 
                        <td className="text-btn word-break purple-text" dangerouslySetInnerHTML={{ __html: news.ShortDescription }}>
                        </td>
                        : <>
                        <td className="text-btn word-break purple-text" dangerouslySetInnerHTML={{ __html: `<a href=${news.ExternalUrl} target="__blank">${news.ShortDescription}</a>` }}>
                        </td>
                        </>}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {(dataList?.length === 0 && !loader) &&<div className="table__blank">
            <div className="table__blank--text">No Data</div>
          </div> }
          {dataList?.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(dataList)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
