import { fetch } from "../../utils";
import request from "./request";
import { get, _ } from "lodash";
const HostName = process.env.REACT_APP_API_HOSTNAME;
// const Cm_HostName = process.env.REACT_APP_API_CM_HOSTNAME;
export const login = (value) => {
  return fetch(`${HostName}/auth/SendAuthCode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const verifyOtp = (value) => {
  return fetch(`${HostName}/auth/VerifyAuthCode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const VerifyEmail = (value) => {
  return fetch(`${HostName}/auth/VerifyEmailId`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const addEvent = (value) => {
  return fetch(`${HostName}/Dashboard/AddEvent`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateAttendeesPermissions = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateAttendeesPermissions`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const removeAppUserToEventAttendees = (value) => {
  return fetch(`${HostName}/Dashboard/DeleteEventAttendees`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const addAppUserToEventAttendees = (value) => {
  return fetch(`${HostName}/Dashboard/AddAppUserToEventAttendees`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const addAppUserToEvent = (value) => {
  return fetch(`${HostName}/Dashboard/AddAppUserToEvent`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateEventSettings = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateEventSettings`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const addUser = (value) => {
  return fetch(`${HostName}/Dashboard/AddUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const appUserDelete = (value) => {
  return fetch(`${HostName}/Dashboard/AppUser`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getAppUsers = (value) => {
  return request(
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${HostName}/Dashboard/GetAppUsers?count=${value.count}&page=${value.page}&q=${value.query}`,
    },
    value.token
  )
    .then((data) => data)
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getEventAttendees = (value) => {
  return request(
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${HostName}/Dashboard/GetEventAttendees?eventId=${value.eventId}&count=${value.count}&page=${value.page}&q=${value.query}`,
    },
    value.token
  )
    .then((data) => data)
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getEventDetails = (value) => {
  return fetch(`${HostName}/Dashboard/GetEventDetails?EventId=${value?.Id}`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },

    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getEventSettings = (value) => {
  return fetch(`${HostName}/Dashboard/GetEventSettings?EventId=${value?.Id}`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },

    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getUserDetails = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetUserPermission?aplicationId=${value}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getEvents = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetEvents?Page=${value.page}&Count=${value.count}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getMe = (value) => {
  return fetch(`${HostName}/userprofile/DefaultAction?id=Current_User`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },

    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      // localStorage.removeItem("Authorization")
      // window.location.href = '/login'
      console.error("Error:", error);
    });
};

export const getAgoloNewsByDate = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetAgoloNewsByDate?startDate=${value?.startDate}&endDate=${value?.endDate}&isThisWeek=${value?.isThisWeek}&status=${value?.status}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getAllCreatedAgoloNewsDate = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetAllCreatedAgoloNewsDate?startDate=${value?.startDate}&endDate=${value?.endDate}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const getAllCreatedDraftAgoLoNewsDate = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetAllCreatedDraftAgoloNewsDate?startDate=${value?.startDate}&endDate=${value?.endDate}`,
    {
      method: "GET",
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const getAgoloNewsByDateNew = (value) => {
  return request(
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${HostName}/Dashboard/GetAgoloNewsByDate?startDate=${value?.startDate}&endDate=${value?.endDate}&isThisWeek=${value?.isThisWeek}&status=${value?.status}`,
    },
    value.token
  )
    .then((data) => data)
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const sendMailOtherNews = (value) => {
  return fetch(`${HostName}/Dashboard/SendMailOtherNews`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getCategoriesByDate = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetCategoriesByDate?startDate=${value?.startDate}&endDate=${value?.endDate}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getCuratedTags = (value) => {
  return fetch(`${HostName}/Dashboard/GetCuratedTags`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },

    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getLtWeeklyThemeCategories = (value) => {
  return fetch(`${HostName}/Dashboard/GetLtWeeklyThemeCategories`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const addCuratedTags = (value) => {
  return fetch(`${HostName}/Dashboard/AddCuratedTag`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getLtWeeklyTheme = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetLtWeeklyThemes?PageNumber=${value?.page}&Count=${value?.count}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getNews = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetAgoloNewsByStatusCount?page=${value?.page}&count=${value?.count}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateTags = (value) => {
  return fetch(`${HostName}/Dashboard/AddLTWeeklyItemSecondaryTags`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getMailingList = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetMailingList?Page=${value.page}&Count=${value.count}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateMailingList = (value) => {
  return fetch(`${HostName}/Dashboard/AddMailingList`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const removeMailingList = (value) => {
  return fetch(`${HostName}/Dashboard/RemoveMailingUserList`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getMailingListById = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetMailingListDetailsById?Id=${value.Id}&Page=${value?.Page}&Count=${value?.Count}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getLtWeeklyItems = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetLtWeeklyItems?WeeklyDate=${value.date}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateLtweeklyDetails = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateLtWeeklyDetails`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const uploadImage = (value) => {
  return fetch(`${HostName}/Dashboard/AddImage`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const removeThemeById = (value) => {
  return fetch(`${HostName}/Dashboard/RemoveThemeById?Id=${value?.Id}`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const AddLTWeekly = (value) => {
  return fetch(`${HostName}/Dashboard/AddLTWeekly`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const sendLtWeeklyEmail = (value) => {
  return fetch(`${HostName}/Dashboard/SendLtWeeklyMail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateArticles = (value) => {
  return fetch(`${HostName}/Dashboard/AddOrUpdateAgoloNews`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const removeAgoloNews = (value) => {
  return fetch(`${HostName}/Dashboard/RemoveAgoloNews?Id=${value?.Id}`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getArticleFromUrl = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetNewsInfoFromURL?url=${value?.url}&weeklyDate=${value?.weeklyDate}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

// export const getAllCampaigns = (value) => {
//   let url = ''
//   if(value?.uri){
//     url = `${HostName}/Dashboard/GetAllCampaigns?PageNumber=${value?.number}&PageUri=${value?.uri}&CampaignType=${value?.campaignType}`
//   }else{
//     url = `${HostName}/Dashboard/GetAllCampaigns?PageNumber=${value?.number}&PageUri=&CampaignType=${value?.campaignType}`
//   }
//   return fetch(url, {
//     method: "GET",
//     // headers: {
//     //   "Content-Type": "application/json",
//     // },
//     // body: JSON.stringify(value),
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((payload) => {
//       return payload;
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// };

export const getCampaignClickUrlsInfo = (value) => {
  let url = `${HostName}/Dashboard/CampaignClickUrlsInfo?page=${value?.page}&count=${value?.count}&urlId=${value?.urlId}`;

  return fetch(url, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const GetCampaignDetailsById = (value) => {
  let url = `${HostName}/Dashboard/GetCampaignDetailsById?Id=${value?.id}&Type=${value?.type}`;

  return fetch(url, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getCampaignClickInfo = (value) => {
  let url = `${HostName}/Dashboard/CampaignClicksInfo?page=${value?.page}&count=${value?.count}&category=${value?.category}`;

  return fetch(url, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const UpdateLTWeeklyOthersNews = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateLTWeeklyOthersNews`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const CampaignSearch = (value) => {
  let url = "";
  if (value?.searchText) {
    url = `${HostName}/Dashboard/CampaignSearch?page=${value?.page}&count=${value?.count}&category=${value?.category}&searchText=${value?.searchText}`;
  } else {
    url = `${HostName}/Dashboard/CampaignSearch?page=${value?.page}&count=${value?.count}&category=${value?.category}`;
  }
  return fetch(url, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const updateMailingListName = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateMailingList`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getTags = (value) => {
  return fetch(`${HostName}/Dashboard/GetTagsCount`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getThemeAndOtherNewByName = (value) => {
  // if(value.sort === 1){
  //   value.sort = true
  // }else{
  //   value.sort = false
  // }
  return fetch(
    `${HostName}/Dashboard/GetLTWeeklyItemOtherNewsByCategory?titleCategory=${value?.titleCategory}&page=${value?.page}&count=${value?.count}&sort=${value?.sort}&type=${value?.type}`,
    {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const GetCategoryCount = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetTagCountByTitleCategory?titleCategory=${value.titleCategory}&type=${value.type}`,
    {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const SendEmailToLtTeam = (value) => {
  return fetch(`${HostName}/Dashboard/SendEmailToLtTeam?date=${value.date}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const GetUsers = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetUsers?page=${value.page}&count=${value.count}&searchText=${value?.searchText}`,
    {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const editProfile = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateUserDetails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const addProfile = (value) => {
  return fetch(`${HostName}/Dashboard/AddUserDetails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

// export const SyncOtherNews = (value) => {
//   return fetch(
//     `${Cm_HostName}/CustomItem/ContentHubToSitecoreNews?year=${value?.year}&month=${value?.month}&day=${value?.day}&status=Completed`,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       // body: JSON.stringify(value),
//     }
//   )
//     .then((res) => {
//       return res.json();
//     })
//     .then((payload) => {
//       return payload;
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// };
// // https://uat-cm.kindredapp.com/CustomItem/ContentHubToSitecoreTheme?ItemName=03-24-2023&DuplicateFrom=04-21-2023
// export const syncLtWeeklyToSitecore = (value) => {
//   return fetch(
//     `${Cm_HostName}/CustomItem/ContentHubToSitecoreTheme?ItemName=${value.ItemName}&DuplicateFrom=04-21-2023`,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       // body: JSON.stringify(value),
//     }
//   )
//     .then((res) => {
//       return res.json();
//     })
//     .then((payload) => {
//       return payload;
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });
// };

export const getSendEmailInformations = (value) => {
  return fetch(`${HostName}/Dashboard/GetFreeFormEmailDetails?Id=${value.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const sendFreeFormLtEmail = (value) => {
  return fetch(`${HostName}/Dashboard/SendFreeFormLTEmail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const getAllCampaigns = (value) => {
  let url = "";
  if (value?.uri) {
    url = `${HostName}/Dashboard/GetAllCampaigns?PageNumber=${value?.page}&Count=${value?.count}&CampaignType=${value?.campaignType}`;
  } else {
    url = `${HostName}/Dashboard/GetAllCampaigns?PageNumber=${value?.page}&Count=${value?.count}&CampaignType=${value?.campaignType}`;
  }
  return request(
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // data: JSON.stringify(credentials),
      url: url,
    },
    get(value, "token", "")
  )
    .then((data) => data)
    .catch((error) => {
      throw error;
    });
};

export const generatePdf = (value) => {
  return fetch(`${HostName}/Dashboard/pdf?WeeklyDate=${value.WeeklyDate}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((data) => data)
    .catch((error) => {
      throw error;
    });
};
export const AddLTWeeklyAuto = (value) => {
  return fetch(
    `${HostName}/Dashboard/AddLTWeeklyAuto?WeeklyDate=${value.WeeklyDate}`,
    {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const UpdateLtweeklyItem = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateLtweeklyItem`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const UpdateLtweeklyDetailsAuto = (value) => {
  return fetch(`${HostName}/Dashboard/UpdateLtweeklyDetailsAuto`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const AddPoll = (value) => {
  return fetch(`${HostName}/Poll/AddPoll`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const GetPollUserDetails = (value) => {
  return fetch(
    `${HostName}/Poll/GetPollUserDetails?Page=${value.Page}&Count=${value.Count}&LtweeklyId=${value.LtweeklyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const GetPollByLtweeklyId = (value) => {
  return fetch(
    `${HostName}/Poll/GetPollByLtweeklyId?LtweeklyId=${value.LtweeklyId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },

      // body: JSON.stringify(value),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const CampaignAnalytics = (value) => {
  return fetch(`${HostName}/Dashboard/CampaignAnalytics`, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const AddNewLTWeeklyEntry = (value) => {
  return fetch(`${HostName}/Dashboard/AddNewLTWeeklyEntry`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    // body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
export const ValidateToken = (value) => {
  const token=localStorage.getItem("Authorization")
    return fetch(`${HostName}/Auth/ValidateToken?token=${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
