import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Close } from "../../components/svgIcons";
import { Spin } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { size } from "lodash";
import ReactModal from "react-modal";
import CSVReader from "react-csv-reader";
import { notification } from "antd";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function AdminComponent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [mailingDetails, setMailingDetails] = useState([]);
  const [selectedNews, setSelectedNews] = useState([]);
  const [page, setPage] = useState(1);
  const [nextStep, setNextStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const [bulkUserIsOpen, setBulkUserIsOpen] = useState(false);
  const [AddUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalled, setIsCallApi] = useState(false);
  const [stateData, setStateData] = useState({});
  const state = location?.state;
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectDeleteRecords, setDeleteRecords] = useState({})
  const ref = useRef();
  const [MailingList, setMailingList] = useState({});
  let { id } = useParams();

  const callApi = async (id) => {
    // const { value } = await props.getMailingListById({ Id: id, Page: 1, Count: 500 });
    // if(value?.Success){
    //   setMailingList(value?.Result?.MailingList);
    // }
    // if (value?.Result?.MailingListUSers?.length > 0) {
    //   setMailingDetails(value?.Result?.MailingListUSers);
    //   let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 500));
    //   setNextStep(nextPageCount);
    //   setLoader(false);
    // }else{
    //   setMailingDetails([]);
    //   setLoader(false);
    // }
  };

  const fileHandler = async (records) => {
    console.log(records,'records')
    setUploadError(false);
    setErrorMessage("")
    setData(records);
    setCancel(true);
  };

  const converTimestamp=(val)=>{
    const dateString = val;

    // Convert the date string to a Date object
    const dateObject = new Date(dateString);
    
    // Get the Unix timestamp (number of milliseconds since January 1, 1970)
    const timestamp = dateObject.getTime() / 1000; // Convert milliseconds to seconds
    return timestamp;
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
        switch (header) {

          // Add more cases for other headers
          default:  
            return header.replace(/\W/g, "_")
      }
    }
  }
  const isCancel = () => {
    setData([]);
    setCancel(false);
  };

  const pageChange = async (value) => {
    setSelectedNews([])
    setAllChecked(true)
    setLoader(false);
    if (value === "next") {
      let pageNum = page + 1;
      setPage(pageNum);
      const { value } = await props.getMailingListById({ Id: id, Page: pageNum, Count: 500 });
      if (value?.Result?.MailingListUSers?.length > 0) {
        setMailingDetails(value?.Result?.MailingListUSers);
        setMailingList(value?.Result?.MailingList);
        let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 500));
        setNextStep(nextPageCount);
        setLoader(false);
      }
    } else if (value === "previous") {
      let pageNum = page - 1 === 0 ? 1 : page - 1;
      setPage(pageNum);
      const { value } = await props.getMailingListById({ Id: id, Page: pageNum, Count: 500 });
      if (value?.Result?.MailingListUSers?.length > 0) {
        setMailingDetails(value?.Result?.MailingListUSers);
        setMailingList(value?.Result?.MailingList);
        let nextPageCount = parseInt(Math.ceil(value?.Result?.TotalCount / 500));
        setNextStep(nextPageCount);
        setLoader(false);
      }
    }
    setLoader(false);
  };

  const closeBulkUserModal = () => {
    setBulkUserIsOpen(false);
  };

  function addUserModalClose() {
    setAddUserModalIsOpen(false);
  }
  function closeModal() {
    // setDeletedRecord({})
    setDeleteModal(false)
    // setIsOpen(false);
  }
  const onCheck = async (item, key = "single") => {
    if (key === "single") {
      const found = selectedNews.findIndex((el) => el.Id === item.Id);
      if (found === -1) {
        setSelectedNews([...selectedNews, item]);
        if (selectedNews?.length + 1 === mailingDetails.length) {
          setAllChecked(false);
        }
      } else {
        selectedNews.splice(found, 1);
        setAllChecked(true);
        setSelectedNews([...selectedNews]);
      }
    } else {
      const markData = [];
      if (!allChecked) {
        setAllChecked(true);
        setSelectedNews([]);
      } else {
        let agolo = mailingDetails.map((item, index) => {
          markData.push(item);
        });
        await Promise.all(agolo);
        setSelectedNews(markData);
        setAllChecked(false);
      }
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "LionTree",
      description: message,
    });
  };

  const removeMailingList = async() => {
    setIsLoading(false)
    let list = [selectDeleteRecords]
    if(selectedNews?.length > 0){
      let prm  = selectedNews?.map(s=>{
        list.push(s);
      })
      await Promise.all(prm)
    }
    let str = ""
    let prms = list?.map(l=>{
      if(str){
        if(!str?.includes(l.Id)){
            str = str + "|" + l.Id
        }
      }else{
        if(!str?.includes(l.Id)){
          str = l.Id?.toString() 
        }
      }
    })
    await Promise.all(prms)
    let payload = {
      UserListIds: str,
      MailingListId: id
    }
    const { value } = await props.removeMailingList(payload);
    if(value?.Success){
      // let timeInterval = setInterval(()=>{
        closeModal()
        let message = `Removed ${str?.split("|")?.length} User successfully`
        openNotificationWithIcon("success", message);
        setSelectedNews([])
        callApi(id)
        setAllChecked(true)
        setLoader(false)
        setDeleteRecords({})
        setIsLoading(false)
        // clearInterval(timeInterval);
      // }, 1000)
    }else{
      closeModal()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isCalled) {
      setStateData(state?.data);
      callApi(id);
      setIsCallApi(true);
    }
  }, [mailingDetails]);

  const uploadList = async () => {
    if(data?.length > 0){
    console.log(data,'data')
        //   setIsLoading(false)
        for(var i=0;i<=10;i++){
            const { value } = await props.CampaignAnalytics([data[i]]); 
            // if(value?.Success){
            //   console.log(value,'value---->')
          //     setIsLoading(false)
          //     setData([])
          //     setCancel(false)
          //     addUserModalClose()
          //     setLoader(false)
          //     callApi(id)
          //     setMailingDetails([])
          //   }
        //   }else{
          //   setUploadError(true);
          //   setErrorMessage("No Data Found.")
        //   }
        }

}
  };

  return (
    <>
      <main className="main viewother__news-wrapper" role="head">
        <div className="main__header">
          <header className="head">
            <div className="head__container">
              <div className="head__row">
                <div className="head__left">
                  <div className="head__close">
                    <button
                      onClick={() => navigate("/mailing-list")}
                      className="btn btn__close"
                    >
                      <Close />
                    </button>
                  </div>
                  <div className="head__divider"></div>
                  <div className="head__title">
                    {MailingList?.MailingDate}
                    
                  </div>
                </div>
                <div className="head__right">
                  <button
                      onClick={() => {
                        setAddUserModalIsOpen(!AddUserModalIsOpen);
                      }}
                      type="button"
                      className="btn btn__purple"
                    >
                      Add to List
                    </button>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className="custom__container">
          <div className="all__slect">
            <label className="custom__checkbox">
              <input
                type="checkbox"
                className="custom__checkbox--input"
                checked={!allChecked}
                onChange={(e) => onCheck({}, "All")}
              />
              <span className="custom__checkbox--check"></span>
            </label>
            <button
              disabled={!selectedNews.length}
              onClick={() => setDeleteModal(true)}
              className="btn btn__purple"
            >
              Bulk Remove User(s)
            </button>
            {/* <button
              onClick={() => {
                setAddUserModalIsOpen(!AddUserModalIsOpen);
              }}
              type="button"
              className="btn btn__purple"
            >
              Add to List
            </button> */}
          </div>
          <div className="table-responsive table__responsive--custom">
            <table className="table table__custom">
              <thead>
                <tr>
                  {mailingDetails?.length > 0 ? 
                    <th className="table__selection--column">Email</th>
                    : 
                    <th>Email</th>
                  }
                  <th>Full Name</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <Spin
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 30, color: "#000" }}
                        spin
                      />
                    }
                    className="loader__full"
                  />
                ) : (
                  mailingDetails?.length > 0 &&
                  mailingDetails?.map((news, i) => {
                    return (
                      <tr key={i}>
                        <td className="table-description">
                          <label className="custom__checkbox">
                            <input
                              type="checkbox"
                              className="custom__checkbox--input"
                              name="duplex"
                              value={i}
                              checked={
                                selectedNews
                                  ?.map((e) => e.Id)
                                  ?.indexOf(news.Id) !== -1
                              }
                              onChange={() => onCheck(news)}
                            />
                            <span className="custom__checkbox--check"></span>
                            {news.Email}
                          </label>
                        </td>
                        <td>{news.FullName}</td>
                        <td> {news.FirstName}</td>
                        <td className="table__status">
                          {news.LastName}
                        </td>
                        <td className="text-end">
                          <button
                            onClick={()=>{
                              setSelectedNews([])
                              setAllChecked(true)
                              setDeleteModal(true)
                              setDeleteRecords(news)
                            }
                          }
                            className="remove-btn"
                          >
                            Remove User
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          {(mailingDetails?.length === 0 && !loader) &&<div className="table__blank">
            <div className="table__blank--text">No Data</div>
          </div> }
          {mailingDetails?.length > 0 && (
            <div className="table__pagination">
              <div className="table__pagination--results">
                {size(mailingDetails)} results
              </div>
              <div className="table__pagination--nav">
                <>
                  {page - 1 >= 1 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--prev"
                      onClick={() => pageChange("previous")}
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--prev disabled"
                    >
                      Previous
                    </button>
                  )}
                </>
                <>
                  {nextStep - page > 0 ? (
                    <button
                      type="button"
                      className="btn btn__default table__pagination--next"
                      onClick={() => pageChange("next")}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled
                      className="btn btn__default table__pagination--next disabled"
                    >
                      Next
                    </button>
                  )}
                </>
              </div>
            </div>
          )}
        </div>

        <ReactModal
          isOpen={AddUserModalIsOpen}
          onRequestClose={addUserModalClose}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered addcurated__list-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Add users to List</h5>
            </div>
            <form ref={ref} noValidate>
            <div className="react-modal-body">
              <div className="form-group">
                <label className="label-primary">{MailingList?.MailingDate} List</label>

                <div className="upload__file-section">
                  <label className="label-primary">
                    Mailing List
                  </label>
                  <div className="dropzone-file">
                    <button type="button" className="btn btn__purple">
                      Upload File
                    </button>
                    <CSVReader
                      cssClass="csv-reader-uploader"
                      onFileLoaded={(data) => {
                        const manipulatedData = data.map((row,i) => {
                            // console.log(row,'row')
                            // Example manipulation: Multiply the first value of each row by 2
                            row.timestamp = converTimestamp(row.timestamp);
                            row.category = JSON.parse(row.category);
                            row.sg_machine_open = 0;
                            return row;
                          });
                        fileHandler(manipulatedData);
                        ref.current?.reset();
                      }}
                      onError={fileHandler}
                      parserOptions={papaparseOptions}
                      inputId="ObiWan"
                    />
                  </div>
                  {cancel === true && (
                    <div className="upload-content">
                      <button
                        className="remove-csv-file"
                        onClick={() => isCancel()}
                      >
                        <CloseOutlined />
                      </button>
                      <img src="https://www.spreadsheetweb.com/wp-content/uploads/2021/01/How-to-avoid-formatting-change-on-CSV-files-in-Excel-00.png" />
                    </div>
                  )}
                  {uploadError && (
                    <div className="invalid-feedback">{errorMessage}</div>
                  )}
                </div>
              </div>
            </div>
            </form>
            <div className="react-modal-footer">
              <button
              onClick={() => {
                  setData([]);
                  addUserModalClose()
                  isCancel();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                onClick={() => uploadList()}
                type="button"
                className="btn btn__purple"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "Upload List"}
              </button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={deleteModal}
          onRequestClose={closeModal}
          ariaHideApp={false}
          contentLabel="Are you sure?"
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title ">Delete</h5>
            </div>
            <div className="react-modal-body">
              <p className="react-modal-body-text">
                Are you sure you want to delete this ? This cannot be undone.
              </p>
            </div>
            <div className="react-modal-footer">
              <button onClick={closeModal} className="btn btn__default">
                Cancel
              </button>
              <button
                onClick={() => removeMailingList()}
                className="btn btn__purple ms-2"
              >
               {isLoading ? <Spin indicator={antIcon} /> : "Yes, Delete" }
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
    </>
  );
}
